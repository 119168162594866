import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../../components/global/Layout";
import SectionParagraph from "../../components/ui/SectionParagraph";
import SectionTitle from "../../components/ui/SectionTitle";

const ZlomStalowyPage = () => {
  return (
    <Layout
      titleTemplate="%s"
      pageTitle="Skup złomu stalowego - wsadowego, blachy, żeliwnego"
      pageDescription="Nasz zakład zajmuje się skupem złomu stalowego takiego jak złom stalowy wsadowy, złom stalowy blacha, złom stalowy oprodukcja czy złom żeliwny."
    >
      <section className="container mx-auto px-5 py-16">
        <div className="flex flex-row gap-6 justify-between items-center flex-wrap lg:flex-nowrap space-x-8">
          <div className="md:w-1/2">
            <StaticImage
              src="../../images/services/stal.jpg"
              alt="Złom stalowy"
              width={800}
              height={600}
            />
          </div>
          <div className="md:w-1/2">
            <SectionTitle size="small">Złom stalowy</SectionTitle>
            <SectionParagraph className="mb-5">Skupujemy:</SectionParagraph>
            <ul className="list-disc text-xl text-gray-700 pl-5">
              <li>złom stalowy wsadowy</li>
              <li>złom stalowy blacha</li>
              <li>złom stalowy poprodukcja</li>
              <li>złom stalowy konstrukcja</li>
              <li>złom żeliwny</li>
            </ul>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default ZlomStalowyPage;
